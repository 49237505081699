<template>
    <div>
        <Head v-if="!isApp" />
        <Floating v-if="!isApp" />
        <div class="wrapper list_main" v-if="PostData">
            <div class="main_bg">
                <nav class="breadcrumb_box" v-if="!isApp">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">{{ $t("首頁") }}</router-link></li>
                            <li class="breadcrumb-item" v-if="PostData.Category">
                                <router-link :to="{ name: 'Category', params: '{ CId: PostData.Category.CId }' }"> {{
                                    PostData.Category.CategoryName }}</router-link>
                            </li>
                            <li class=" breadcrumb-item active" aria-current="page">{{ PostData.Post.Title }}</li>
                        </ol>
                    </div>
                </nav>
                <section class="main">
                    <div class="container">
                        <div class="title">
                            <h1>{{ PostData.Post.Title }}</h1>
                        </div>
                        <div class="link_box">
                            <p class="time">{{ PostData.Post.PublicStartTime | timeString("YYYY-MM-DD") }}</p>
                            <ul class="link">
                                <li>
                                    <a :href="PostData.ShareUrl_Facebook" target="_blank"
                                        :title="$t('分享至Facebook, 另開新視窗')"><img src="/img/icon_fb.svg" alt=""></a>
                                </li>
                                <li>
                                    <a :href="PostData.ShareUrl_Line" target="_blank" :title="$t('分享至Line, 另開新視窗')"><img
                                            src="/img/icon_line.svg" alt=""></a>
                                </li>
                                <!--<li>
                                    <a href=""><img src="/img/icon_share.svg" alt=""></a>
                                </li> -->
                            </ul>
                        </div>
                        <slick class="marquee_main" v-if="Slideshows" :options="{
                            dots: true,
                            autoplay: true,
                            autoplaySpeed: 5000,
                        }">
                            <div class="box" v-for="(Slideshow, index) in Slideshows " :key="index">
                                <a :href="PostData.SiteUrl + Slideshow.Url" target="_blank"
                                    :title="Slideshow.DisplayName">
                                    <img :src="PostData.SiteUrl + Slideshow.Url" :alt="Slideshow.DisplayName" />
                                    <p>{{ Slideshow.DisplayName }}</p>
                                </a>
                            </div>
                        </slick>
                        <div class="taxt" v-html="PostData.Post.Content">
                        </div>
                        <div class="download_box" v-if="Attachments">
                            <h2>{{ $t("附件下載") }}</h2>
                            <ul class="list">
                                <li v-for="( Attachment, index ) in Attachments " :key="index">
                                    <a :href="PostData.SiteUrl +
                                        '/Platform/Html/Download?FId=' +
                                        Attachment.FId
                                        " target="_blank" :title="$t('下載') + Attachment.DisplayName + $t(', 另開新視窗')">{{
                                        Attachment.DisplayName }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu v-if="!isApp" />
        <Footer v-if="!isApp" />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";
import Slick from "vue-slick";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

const messages = {
    zh_tw: {
        首頁: "首頁",
        "分享至Facebook, 另開新視窗": "分享至Facebook, 另開新視窗",
        "分享至Line, 另開新視窗": "分享至Line, 另開新視窗",
        "附件下載": "附件下載",
        "下載": "下載",
        ", 另開新視窗": ", 另開新視窗",
    },
    en: {
        首頁: "Home",
        "分享至Facebook, 另開新視窗": "Share to Facebook, Open in new window",
        "分享至Line, 另開新視窗": "Share to Line, Open in new window",
        "附件下載": "Attachment",
        "下載": "Downliad",
        ", 另開新視窗": ", Open in new window",
    },
};

const i18n = new VueI18n({
    locale: "zh_tw",
    messages,
});

export default {
    name: 'Post',
    i18n,
    metaInfo: {
        meta: [
            { property: "og:url", content: "" },
            {
                property: "og:type",
                content: "article",
            },
            {
                property: "og:title",
                content: "",
            },
            {
                property: "og:description",
                content: "",
            },
            {
                property: "og:image",
                content: "",
            },
            {
                property: "og:image:secure_url",
                content: "",
            },
            {
                property: "og:image:width",
                content: "",
            },
            {
                property: "og:image:height",
                content: "",
            },
            {
                property: "og:url",
                content: "",
            },
        ],
    },
    metaInfo() {
        return {
            meta: [
                { property: "og:url", content: this.og_url },
                {
                    property: "og:type",
                    content: "article",
                },
                {
                    property: "og:title",
                    content: this.og_title,
                },
                {
                    property: "og:description",
                    content: this.og_description,
                },
                {
                    property: "og:image",
                    content: this.og_image,
                },
                {
                    property: "og:image:secure_url",
                    content: this.og_image_secure_url,
                },
                {
                    property: "og:image:width",
                    content: this.og_image_width,
                },
                {
                    property: "og:image:height",
                    content: this.og_image_height,
                },
            ],
        };
    },
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
        Slick,
    },
    data() {
        return {
            PostData: null,
            Attachments: null,
            Slideshows: null,
            local: "zh_tw",
            og_url: "",
            og_title: "",
            og_description: "",
            og_image: "",
            og_image_secure_url: "",
            og_image_width: "",
            og_image_height: "",
        };
    },
    props: {
        isApp: {
            type: Boolean,
            default: false
        }
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src + this.$store.state.subDirectory + "/Html/Post",
                {
                    params: { PId: this.$route.params.PId, CId: this.$route.params.CId },
                }
            )
            .then((response) => {
                this.PostData = response.data;
                if (this.PostData.Post.Attachment != "[]") {
                    this.Attachments = JSON.parse(this.PostData.Post.Attachment);
                }
                if (this.PostData.Post.Slideshow != "[]") {
                    this.Slideshows = JSON.parse(this.PostData.Post.Slideshow);
                }

                this.og_url =
                    this.PostData.SiteUrl + "/#/Post/" + this.PostData.Post.PId;
                this.og_title = this.PostData.Post.Title;
                if (this.PostData.Post.SimpleContent) {
                    this.og_description = this.PostData.Post.SimpleContent;
                } else {
                    this.og_description = this.PostData.Post.Title;
                }
                if (this.PostData.Post.Image) {
                    this.og_image = this.PostData.SiteUrl + this.PostData.Post.Image;
                    this.og_image_secure_url =
                        this.PostData.SiteUrl + this.PostData.Post.Image;
                    this.og_image_width = "600";
                    this.og_image_height = "315";
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    beforeRouteUpdate(to, from, next) {
        Vue.axios
            .get(
                this.$store.state.src + this.$store.state.subDirectory + "/Html/Post",
                {
                    params: { PId: to.params.PId, CId: to.params.CId },
                }
            )
            .then((response) => {
                this.PostData = response.data;
                if (this.PostData.Post.Attachment != "[]") {
                    this.Attachments = JSON.parse(this.PostData.Post.Attachment);
                } else {
                    this.Attachments = null;
                }
                if (this.PostData.Post.Slideshow != "[]") {
                    this.Slideshows = JSON.parse(this.PostData.Post.Slideshow);
                } else {
                    this.Slideshows = null;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        next();
    },
    updated: function () {
        $('pre').replaceWith(function () {
            return $("<p />", { html: $(this).html() });
        });
    },
    mounted: function () {
        if ($cookies.isKey("aow_site_lang")) {
            i18n.locale = $cookies.get("aow_site_lang");
            this.local = $cookies.get("aow_site_lang");
        } else {
            i18n.locale = $cookies.get("aow_site_lang");
            this.local = "zh_tw";
        }
    },
    methods: {},
}
</script>