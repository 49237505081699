<template>
    <div>
        <Head v-if="!isApp" />
        <Floating v-if="!isApp" />
        <!-- 文字列表 -->
        <div class="wrapper list list_2" v-if="CategoryData && CategoryData.Category.Type == 0">
            <!-- 背景 -->
            <div class="main_bg">
                <!-- 麵包屑 -->
                <nav class="breadcrumb_box" v-if="!isApp">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">{{ $t("首頁") }}</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ CategoryData.Category.CategoryName
                                }}
                            </li>
                        </ol>
                    </div>
                </nav>
                <!-- 最新消息 列表頁 -->
                <section class="news">
                    <div class="container">
                        <div class="title">
                            <h1>{{ CategoryData.Category.CategoryName }}</h1>
                            <!-- <div class="btn_box">
                                <button>全部消息</button>
                                <button>新聞發佈</button>
                                <button>公告消息</button>
                            </div> -->
                        </div>
                        <div class="bg">
                            <ul class="list show">
                                <li v-for="Post in Posts" :key="Post.PId">
                                    <router-link :to="{ name: 'Post', params: { PId: Post.PId }, query: { type: isApp ? 'App' : '' } }">
                                        <!-- <div class="tag_box">
                                            <div class="tag">置頂</div>
                                            <div>新聞</div>
                                        </div> -->
                                        <div class="text">
                                            <p class="time">{{ Post.PublicStartTime | timeString("YYYY-MM-DD") }}</p>
                                            <p>{{ Post.Title }}</p>
                                        </div>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
                            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
                            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
                            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false"
                            v-if="pageAll > 1">
                        </paginate>
                    </div>
                </section>
            </div>
        </div>
        <!-- 圖文列表 -->
        <div class="wrapper list list_1" v-if="CategoryData && CategoryData.Category.Type == 1">
            <div class="main_bg">
                <nav class="breadcrumb_box" v-if="!isApp">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">{{ $t("首頁") }}</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ CategoryData.Category.CategoryName
                                }}
                            </li>
                        </ol>
                    </div>
                </nav>
                <section class="news">
                    <div class="container">
                        <div class="title">
                            <h1>{{ CategoryData.Category.CategoryName }}</h1>
                            <!-- <div class="btn_box">
                                <button>全部消息</button>
                                <button>新聞發佈</button>
                                <button>公告消息</button>
                            </div> -->
                        </div>
                        <div class="bg">
                            <ul class="list show">
                                <li v-for="Post in Posts" :key="Post.PId">
                                    <router-link :to="{ name: 'Post', params: { PId: Post.PId }, query: { type: isApp ? 'App' : '' } }">
                                        <!-- <div class="tag_box">
                                            <div class="tag">置頂</div>
                                            <div>新聞</div>
                                        </div> -->
                                        <div class="img_box">
                                            <div class="img">
                                                <img src="/img/img_1.png" alt="" v-if="!Post.Image">
                                                <img :src="CategoryData.SiteUrl + Post.Image" alt="" v-if="Post.Image">
                                            </div>
                                        </div>
                                        <div class="text">
                                            <p class="time">{{ Post.PublicStartTime | timeString("YYYY-MM-DD") }}</p>
                                            <p>{{ Post.Title }}</p>
                                        </div>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
                            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
                            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
                            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false"
                            v-if="pageAll > 1">
                        </paginate>
                    </div>
                </section>
            </div>
        </div>
        <!-- 動物之家列表 -->
        <div class="wrapper list list_3" v-if="CategoryData && CategoryData.Category.Type == 2">
            <div class="main_bg">
                <nav class="breadcrumb_box" v-if="!isApp">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">{{ $t("首頁") }}</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ CategoryData.Category.CategoryName
                                }}
                            </li>
                        </ol>
                    </div>
                </nav>
                <section class="news">
                    <div class="container">
                        <div class="title">
                            <h1>{{ CategoryData.Category.CategoryName }}</h1>
                        </div>
                        <div class="bg">
                            <ul class="list row show" v-if="!CategoryData.Category.IsShowSub">
                                <li v-for="Post in Posts" :key="Post.PId" class="col-12 col-sm-6 col-md-4">
                                    <router-link :to="{ name: 'Post', params: { PId: Post.PId }, query: { type: isApp ? 'App' : '' } }">
                                        <p>{{ Post.Title }}</p>
                                    </router-link>
                                </li>
                            </ul>
                            <ul class="list row show" v-if="CategoryData.Category.IsShowSub">
                                <li v-for="Category in CategoryData.Categorys" :key="Category.CId"
                                    class="col-12 col-sm-6 col-md-4">
                                    <router-link :to="{ name: 'Category', params: { CId: Category.CId } }">
                                        <p>{{ Category.CategoryName }}</p>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
                            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
                            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
                            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false"
                            v-if="pageAll > 1">
                        </paginate>
                    </div>
                </section>
            </div>
        </div>
        <!-- 優良店家列表 -->
        <div class="wrapper list_4" v-if="CategoryData && CategoryData.Category.Type == 3">
            <div class="main_bg">
                <nav class="breadcrumb_box" v-if="!isApp">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">{{ $t("首頁") }}</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ CategoryData.Category.CategoryName
                                }}
                            </li>
                        </ol>
                    </div>
                </nav>
                <section class="main">
                    <div class="container">
                        <div class="title">
                            <h1>{{ CategoryData.Category.CategoryName }}</h1>
                        </div>
                        <div class="bg">
                            <div class="search_box">
                                <div class="form-group ">
                                    <select class="form-control" v-model="StoreArea">
                                        <option v-for="(_Type, index) in CategoryData.StoreAreas" :key="index"
                                            :value="index == 0 ? '' : _Type">{{ _Type }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="" placeholder="請輸入關鍵字"
                                        v-model="Keyword">
                                </div>
                                <button class="" @click="loadList(1)">搜尋</button>
                            </div>
                            <div class="box">
                                <ul class="list show">
                                    <li v-for="Post in Posts" :key="Post.PId">
                                        <router-link :to="{ name: 'Post', params: { PId: Post.PId }, query: { type: isApp ? 'App' : '' } }">
                                            <div class="img_box">
                                                <div class="img">
                                                    <img src="/img/img_1.png" alt="" v-if="!Post.Image">
                                                    <img :src="CategoryData.SiteUrl + Post.Image" alt=""
                                                        v-if="Post.Image">
                                                </div>
                                            </div>
                                            <div class="text_box">
                                                <div class="address">
                                                    <h2>{{ Post.Title }}</h2>
                                                    <p>{{ Post.StoreAddress }}</p>
                                                </div>
                                                <div class="telephone">
                                                    <p>{{ $t("電話") }}:{{ Post.StorePhone }}</p>
                                                    <p>{{ $t("營業項目") }}:{{ Post.StoreItems }}</p>
                                                    <p>{{ $t("特寵業字號") }}:{{ Post.StoreNumber }}</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
                            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
                            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
                            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false"
                            v-if="pageAll > 1">
                        </paginate>
                    </div>
                </section>
            </div>
        </div>
        <!-- 問與答列表 -->
        <div class="wrapper qna" v-if="CategoryData && CategoryData.Category.Type == 4">
            <div class="main_bg">
                <nav class="breadcrumb_box" v-if="!isApp">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">{{ $t("首頁") }}</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ CategoryData.Category.CategoryName
                                }}
                            </li>
                        </ol>
                    </div>
                </nav>
                <section class="news">
                    <div class="container">
                        <div class="title">
                            <h1>{{ CategoryData.Category.CategoryName }}</h1>
                        </div>
                        <div class="bg">
                            <ul class="list show">
                                <li class="box" v-for="Post in Posts" :key="Post.PId">
                                    <h2 style="cursor: pointer;"
                                        @click="QAIndex == Post.PId ? QAIndex = -1 : QAIndex = Post.PId">{{ Post.Title
                                        }}
                                    </h2>
                                    <div class="answer" style="display: block;" v-html="Post.Content"
                                        v-show="QAIndex == Post.PId">
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
                            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
                            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
                            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false"
                            v-if="pageAll > 1">
                        </paginate>
                    </div>
                </section>
            </div>
        </div>
        <!-- 單位資訊列表 -->
        <div class="wrapper download_list" v-if="CategoryData && CategoryData.Category.Type == 5">
            <div class="main_bg">
                <nav class="breadcrumb_box" v-if="!isApp">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">{{ $t("首頁") }}</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ CategoryData.Category.CategoryName
                                }}
                            </li>
                        </ol>
                    </div>
                </nav>
                <section class="news">
                    <div class="container">
                        <div class="title">
                            <h1>{{ CategoryData.Category.CategoryName }}</h1>
                        </div>
                        <div class="bg">
                            <div class="search_box">
                                <div class="form-group ">
                                    <select class="form-control" v-model="ServiceType">
                                        <option v-for="(_Type, index) in CategoryData.ServiceTypes" :key="index"
                                            :value="index == 0 ? '' : _Type">{{ _Type }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="" placeholder="請輸入關鍵字"
                                        v-model="Keyword">
                                </div>
                                <button class="" @click="loadList(1)">搜尋</button>
                            </div>
                            <ul class="list show">
                                <table>
                                    <thead>
                                        <tr>
                                            <th class="basis_100">{{ $t("發佈時間") }}</th>
                                            <th>{{ $t("主旨") }}</th>
                                            <th class="basis_150">{{ $t("發布單位") }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="Post in Posts" :key="Post.PId">
                                            <td data-th="發佈時間" class="basis_100">{{ Post.PublicStartTime |
                                                timeString("YYYY-MM-DD") }}</td>
                                            <td data-th="主旨"><router-link
                                                    :to="{ name: 'Post', params: { PId: Post.PId }, query: { type: isApp ? 'App' : '' } }">{{
                                                        Post.Title }}</router-link></td>
                                            <td data-th="發布單位" class="basis_100">{{ Post.ServiceUnit }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ul>
                        </div>
                        <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
                            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
                            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
                            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false"
                            v-if="pageAll > 1">
                        </paginate>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu v-if="!isApp" />
        <Footer v-if="!isApp" />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
    zh_tw: {
        首頁: "首頁",
        電話: "電話",
        營業項目: "營業項目",
        特寵業字號: "特寵業字號",
        發佈時間: "發佈時間",
        主旨: "主旨",
        發布單位: "發布單位",
    },
    en: {
        首頁: "Home",
        電話: "Telephone",
        營業項目: "Business",
        特寵業字號: "Number",
        發佈時間: "Public Time",
        主旨: "Title",
        發布單位: "Public Unit",
    },
};

const i18n = new VueI18n({
    locale: "zh_tw",
    messages,
});

export default {
    name: 'Category',
    i18n,
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            CategoryData: null,
            Posts: null,
            pageAll: 0,
            perPage: 10,
            QAIndex: -1,
            local: "zh_tw",
            StoreArea: '',
            ServiceType: '',
            Keyword: '',
        };
    },
    props: {
        isApp: {
            type: Boolean,
            default: false
        }
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src +
                this.$store.state.subDirectory +
                "/Html/Category",
                {
                    params: { CId: this.$route.params.CId, lang: $cookies.get("aow_site_lang") },
                }
            )
            .then((response) => {
                this.CategoryData = response.data;
                if (this.CategoryData.Category.Type == 2) {
                    this.perPage = 1000;
                }
                this.loadList(1);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    beforeRouteUpdate(to, from, next) {
        Vue.axios
            .get(
                this.$store.state.src +
                this.$store.state.subDirectory +
                "/Html/Category",
                {
                    params: { CId: to.params.CId, lang: $cookies.get("aow_site_lang") },
                }
            )
            .then((response) => {
                this.CategoryData = response.data;
                if (this.CategoryData.Category.Type == 2) {
                    this.perPage = 1000;
                }
                this.loadList(1);
            })
            .catch((error) => {
                console.log(error);
            });
        next();
    },
    mounted: function () {
        if ($cookies.isKey("aow_site_lang")) {
            i18n.locale = $cookies.get("aow_site_lang");
            this.local = $cookies.get("aow_site_lang");
        } else {
            i18n.locale = $cookies.get("aow_site_lang");
            this.local = "zh_tw";
        }
    },
    methods: {
        loadList: function (page) {
            var that = this;
            $.ajax({
                url:
                    this.$store.state.src +
                    this.$store.state.subDirectory +
                    "/Html/PostList",
                type: "post",
                data: {
                    CId: this.$route.params.CId,
                    page: page,
                    perPage: that.perPage,
                    storeArea: that.StoreArea,
                    serviceType: that.ServiceType,
                    keyword: that.Keyword,
                    lang: $cookies.get("aow_site_lang"),
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    if (res.status == 1) {
                        this.QAIndex = -1;
                        that.Posts = res.Posts;
                        that.perPage = res.perPage;
                        that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
                        document.body.scrollTop = 0;
                    }
                },
            });
        },
        gotoPost(Post) {
            if (Post.Type == 1) {
                if (Post.Blank) {
                    window.open(Post.Url, "_blank");
                } else {
                    location.href = Post.Url;
                }
            } else {
                this.$router.push({
                    name: "Post",
                    params: { PId: Post.PId, CId: this.CategoryData.Category.CId },
                });
            }
        },
        gotoCategoryByCId: function (CId) {
            this.$router.push({
                name: "Category",
                params: { CId: CId },
            });
        },
    },
}
</script>