var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isApp)?_c('Head'):_vm._e(),(!_vm.isApp)?_c('Floating'):_vm._e(),(_vm.PostData)?_c('div',{staticClass:"wrapper list_main"},[_c('div',{staticClass:"main_bg"},[(!_vm.isApp)?_c('nav',{staticClass:"breadcrumb_box"},[_c('div',{staticClass:"container"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("首頁")))])],1),(_vm.PostData.Category)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'Category', params: '{ CId: PostData.Category.CId }' }}},[_vm._v(" "+_vm._s(_vm.PostData.Category.CategoryName))])],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(_vm._s(_vm.PostData.Post.Title))])])])]):_vm._e(),_c('section',{staticClass:"main"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.PostData.Post.Title))])]),_c('div',{staticClass:"link_box"},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("timeString")(_vm.PostData.Post.PublicStartTime,"YYYY-MM-DD")))]),_c('ul',{staticClass:"link"},[_c('li',[_c('a',{attrs:{"href":_vm.PostData.ShareUrl_Facebook,"target":"_blank","title":_vm.$t('分享至Facebook, 另開新視窗')}},[_c('img',{attrs:{"src":"/img/icon_fb.svg","alt":""}})])]),_c('li',[_c('a',{attrs:{"href":_vm.PostData.ShareUrl_Line,"target":"_blank","title":_vm.$t('分享至Line, 另開新視窗')}},[_c('img',{attrs:{"src":"/img/icon_line.svg","alt":""}})])])])]),(_vm.Slideshows)?_c('slick',{staticClass:"marquee_main",attrs:{"options":{
                        dots: true,
                        autoplay: true,
                        autoplaySpeed: 5000,
                    }}},_vm._l((_vm.Slideshows),function(Slideshow,index){return _c('div',{key:index,staticClass:"box"},[_c('a',{attrs:{"href":_vm.PostData.SiteUrl + Slideshow.Url,"target":"_blank","title":Slideshow.DisplayName}},[_c('img',{attrs:{"src":_vm.PostData.SiteUrl + Slideshow.Url,"alt":Slideshow.DisplayName}}),_c('p',[_vm._v(_vm._s(Slideshow.DisplayName))])])])}),0):_vm._e(),_c('div',{staticClass:"taxt",domProps:{"innerHTML":_vm._s(_vm.PostData.Post.Content)}}),(_vm.Attachments)?_c('div',{staticClass:"download_box"},[_c('h2',[_vm._v(_vm._s(_vm.$t("附件下載")))]),_c('ul',{staticClass:"list"},_vm._l((_vm.Attachments),function(Attachment,index){return _c('li',{key:index},[_c('a',{attrs:{"href":_vm.PostData.SiteUrl +
                                    '/Platform/Html/Download?FId=' +
                                    Attachment.FId,"target":"_blank","title":_vm.$t('下載') + Attachment.DisplayName + _vm.$t(', 另開新視窗')}},[_vm._v(_vm._s(Attachment.DisplayName))])])}),0)]):_vm._e()],1)])])]):_vm._e(),(!_vm.isApp)?_c('FooterMenu'):_vm._e(),(!_vm.isApp)?_c('Footer'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }